.App {
  text-align: center;
}

.App-logo {
  width: 400px;
  pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 100px;
  padding-bottom: 10px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Login {
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 100px;
}

.form-label {
  font-weight: bold;
}

.itemListMax{
  top:0px;
  left:0px;
  /*position: absolute;*/
  z-index: 0;
  /*float: left;*/
  width:100%;
}

.deleteItem {
  top:0px;
  left:0px;
  position: absolute;
  z-index: 1;
  /*float: right;*/
}

.listAction{
position: relative;
}

.list-group-item.listResource{
  border-style: none;
  padding: 0;
  margin-left: 30px;
  margin-right: 10px;
  text-align: left;
}

.listResource button{
  padding-top: 0px;
  bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}